import { paths } from "@minuthq/minut-api-types/schema"
import { useMutation } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"

import { API_DEFAULT } from "src/constants/minutApi"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { minutApiHttpClient } from "src/utils/minutApiHttpClient"

export type TReportRequestBody =
  paths["/organizations/{organization_id}/reports"]["post"]["requestBody"]["content"]["application/json"]

type TReportRequestResponse =
  paths["/organizations/{organization_id}/reports"]["post"]["responses"]["201"]["content"]["application/json"]

type TReportRequestResponseInvalidError =
  paths["/organizations/{organization_id}/reports"]["post"]["responses"]["400"]["content"]["application/json"]
type TReportRequestResponseForbiddenError =
  paths["/organizations/{organization_id}/reports"]["post"]["responses"]["403"]["content"]["application/json"]

type TReportRequestResponseError =
  | TReportRequestResponseInvalidError
  | TReportRequestResponseForbiddenError

export function usePostReportRequest() {
  const { orgId } = useOrganization()

  async function postReportRequest(requestData: TReportRequestBody) {
    const response = await minutApiHttpClient.post<
      TReportRequestBody,
      AxiosResponse<TReportRequestResponse>
    >(`${API_DEFAULT}/organizations/${orgId}/reports`, requestData)
    return response.data
  }

  return useMutation<
    TReportRequestResponse,
    AxiosError<TReportRequestResponseError>,
    TReportRequestBody
  >({
    mutationFn: postReportRequest,
  })
}

export type TIncidentReportRequestBody =
  paths["/organizations/{organization_id}/reports/incident"]["post"]["requestBody"]["content"]["application/json"]
type TIncidentReportRequestResponse =
  paths["/organizations/{organization_id}/reports/incident"]["post"]["responses"]["201"]["content"]["application/json"]
export type TIncidentReportRequestError =
  | paths["/organizations/{organization_id}/reports/incident"]["post"]["responses"]["400"]["content"]["application/json"]
  | paths["/organizations/{organization_id}/reports/incident"]["post"]["responses"]["403"]["content"]["application/json"]

export function usePostIncidentReportRequest() {
  const { orgId } = useOrganization()

  async function postIncidentReportRequest(
    requestData: TIncidentReportRequestBody
  ) {
    const response = await minutApiHttpClient.post<
      TIncidentReportRequestBody,
      AxiosResponse<TIncidentReportRequestResponse>
    >(`${API_DEFAULT}/organizations/${orgId}/reports/incident`, requestData)
    return response.data
  }

  return useMutation<
    TIncidentReportRequestResponse,
    AxiosError<TIncidentReportRequestError>,
    TIncidentReportRequestBody
  >({
    mutationFn: postIncidentReportRequest,
  })
}
