import styled from "styled-components"

import { HomesDropdown } from "src/components/Dropdown/HomesDropdown"
import { TIncidentReportParams } from "src/components/Reports/useIncidentReportParams"
import {
  TIncidentReportDownloadInitiatedContext,
  usePostIncidentReportDownloadInitiated,
  usePostReportDownloadConfirmed,
} from "src/data/analytics/queries/ReportAnalyticsQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { TMaybeHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchHome } from "src/data/organizations/queries/homeQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useTranslate } from "src/i18n/useTranslate"
import { DatePickerDropdown } from "src/ui/DateRangePicker/DatePickerDropdown"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"
import { MTimePicker } from "src/ui/MTimePicker/MTimePicker"
import { spacing } from "src/ui/spacing"
import { homeTimezoneToUTC, utcToHomeTimezone } from "src/utils/l10n"

import { DurationInput } from "./DurationInput"

export type TIncidentReportOptions = TIncidentReportParams & {
  setHomeId: (id: string | null) => void
  setDate: (newDate: Date | null) => void
  setDuration: (d: number) => void
}

export type IncidentReportDialogProps = {
  reportOptions: TIncidentReportOptions
  dialogProps: Omit<TMDialogProps, "onConfirm"> & {
    onConfirm: (p: { homeId: string; date: Date; duration: number }) => void
  }
  context: TIncidentReportDownloadInitiatedContext
}

export function IncidentReportDialog(props: IncidentReportDialogProps) {
  const noiseIncidentFeature = useFeatureAvailability({
    feature: "reports_incident",
  })

  if (!noiseIncidentFeature.available) {
    // While this check should ideally already have been made by the caller,
    // we're adding an extra here so that a user can never open this dialog
    // unless they have access
    return null
  }

  if (!props.dialogProps.open) {
    return null
  }

  return <OpenedIncidentReportDialog {...props} />
}

function OpenedIncidentReportDialog({
  context,
  reportOptions,
  dialogProps: { onConfirm, ...dialogProps },
}: IncidentReportDialogProps) {
  const calendarPlacement = "bottom"

  const { t, langKeys } = useTranslate()
  const { clock_type } = useGetUser()

  const { orgId } = useOrganization()

  const fetchHome = useFetchHome({ orgId, homeId: reportOptions.homeId ?? "" })
  const fetchedHome = fetchHome.data ?? null
  const homeTimezone = fetchedHome?.timezone

  const postReportDownloadConfirmed = usePostReportDownloadConfirmed()

  const postIncidentReportDownloadInitiated =
    usePostIncidentReportDownloadInitiated()
  useEffectOnce(() => {
    postIncidentReportDownloadInitiated.mutate({ context })
  })

  const validConfirmValues =
    reportOptions.homeId &&
    reportOptions.date &&
    reportOptions.duration &&
    validDuration(reportOptions.duration)
      ? {
          date: reportOptions.date,
          duration: reportOptions.duration,
          homeId: reportOptions.homeId,
        }
      : null

  function getDisplayDate(date: Date | null): Date | null {
    return date ? utcToHomeTimezone(date, homeTimezone) : null
  }
  const displayDate = getDisplayDate(reportOptions.date)

  function setUTCDate(date: Date | null): void {
    const utcDate = date ? homeTimezoneToUTC(date, homeTimezone) : null
    return reportOptions.setDate(utcDate)
  }

  return (
    <MDialog
      {...dialogProps}
      onConfirm={() => {
        postReportDownloadConfirmed.mutate({ type: "incident_report" })
        validConfirmValues && onConfirm(validConfirmValues)
      }}
      confirmLabel={t(langKeys.download)}
      confirmButtonProps={{ disabled: !validConfirmValues }}
      title={t(langKeys.noise_incident_report_header)}
      description={<MText>{t(langKeys.noise_incident_report_body)}</MText>}
      // `responsive` is disabled due to graphical issues when rendered in a
      // deeply nested DOM hierarchy; this is most probably due to problems with
      // the outdated MUI library MDialog is based on and should be hopefully be
      // fixed once we ditch MUI.
      responsive={false}
    >
      <DialogContents>
        <HomesDropdown
          onSelect={function (h: TMaybeHome): void {
            reportOptions.setHomeId(h?.home_id ?? null)
          }}
          value={reportOptions.homeId && fetchedHome ? fetchedHome : null}
          required
        ></HomesDropdown>

        <DatePickerDropdown
          label={t(langKeys.date)}
          date={displayDate}
          setDate={setUTCDate}
          dateRangePickerProps={{ calendarPlacement }}
          required
        ></DatePickerDropdown>

        <MTimePicker
          label={t(langKeys.start_time)}
          time={displayDate}
          setTime={setUTCDate}
          clockType={clock_type}
          required
        ></MTimePicker>

        <DurationInput
          value={reportOptions.duration}
          onChange={reportOptions.setDuration}
        ></DurationInput>
      </DialogContents>
    </MDialog>
  )
}

const DialogContents = styled.div`
  display: grid;
  gap: ${spacing.M};
  padding: ${spacing.M};
`

function validDuration(duration: number | null): boolean {
  return duration != null && duration <= 24 && duration > 0
}
