import { useState } from "react"

import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import {
  TIncidentReportParams,
  useIncidentReportParams,
} from "src/components/Reports/useIncidentReportParams"
import { usePostIncidentReportDownloadClicked } from "src/data/analytics/queries/ReportAnalyticsQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton, TMButtonProps } from "src/ui/Button/MButton"

/** Button to open the noise incident report dialog.
 *
 * 💡 NB: For it to work an instance of IncidentReportDialogContainer must be
 * mounted somewhere in the current view. */
export function CreateNoiseIncidentReportButton({
  hidden,
  size,
  variant,
  label,
  renderChild,
  prefillData,
}: {
  hidden?: boolean
  size?: TMButtonProps["size"]
  label?: string
  variant: TMButtonProps["variant"]
  renderChild?: (p: { featureAvailable?: boolean }) => React.ReactNode
  prefillData?: Partial<TIncidentReportParams>
}) {
  const { t, langKeys } = useTranslate()

  const noiseIncidentFeature = useFeatureAvailability({
    feature: "reports_incident",
  })
  const noiseIncidentReportsAvailable = noiseIncidentFeature.available

  const { setDialogOpen, setDate, setDuration, setHomeId } =
    useIncidentReportParams()

  const [showUpgradeBlocker, setShowUpgradeBlocker] = useState(false)

  const postIncidentReportDownloadClicked =
    usePostIncidentReportDownloadClicked()

  function callTracking() {
    postIncidentReportDownloadClicked.mutate()
  }

  function handleCreateReport() {
    callTracking()
    if (!noiseIncidentReportsAvailable) {
      return setShowUpgradeBlocker(true)
    }
    prefillData?.homeId && setHomeId(prefillData.homeId)
    prefillData?.date && setDate(prefillData.date)
    prefillData?.duration && setDuration(prefillData.duration)

    setDialogOpen(true)
  }

  if (hidden) {
    return null
  }

  return (
    <div>
      <MButton size={size} variant={variant} onClick={handleCreateReport}>
        {label ?? t(langKeys.create_report)}
      </MButton>

      {renderChild &&
        renderChild?.({ featureAvailable: noiseIncidentReportsAvailable })}

      <UpgradeBlockerDialog
        context="incident_reports"
        open={showUpgradeBlocker}
        onClose={() => setShowUpgradeBlocker(false)}
      />
    </div>
  )
}
