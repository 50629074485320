import { useSearchParams } from "src/router/useSearchParams"

export type TIncidentReportParams = {
  homeId: string | null
  date: Date | null
  duration: number | null
}

export function useIncidentReportParams() {
  const { searchParams, setSearchParams } = useSearchParams({
    keys: [
      { key: "homeId", type: "string" },
      { key: "date", type: "string" },
      { key: "duration", type: "number" },
      { key: "showIncidentReportDialog", type: "boolean" },
    ],
  })

  const homeId = searchParams.homeId
  function setHomeId(newHomeId: TIncidentReportParams["homeId"]) {
    setSearchParams("homeId", newHomeId)
  }

  const date = searchParams.date ? new Date(searchParams.date) : null
  function setDate(d: Date | null) {
    setSearchParams("date", d?.toISOString() || null)
  }

  const duration = searchParams.duration
  function setDuration(d: number | null) {
    setSearchParams("duration", d)
  }

  function resetDialogFields() {
    setHomeId(null)
    setDuration(null)
    setDate(null)
  }

  const dialogOpen = searchParams.showIncidentReportDialog
  function setDialogOpen(value: boolean) {
    setSearchParams("showIncidentReportDialog", value, { replace: true })
  }

  return {
    dialogOpen,
    setDialogOpen,
    homeId,
    setHomeId,
    date,
    setDate,
    duration,
    setDuration,
    resetDialogFields,
  }
}
