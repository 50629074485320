import { useTranslate } from "src/i18n/useTranslate"
import { MTextField } from "src/ui/MTextField/MTextField"

export function DurationInput({
  value,
  onChange,
}: {
  value: number | null
  onChange: (newVal: number) => void
}) {
  const { t, langKeys } = useTranslate()
  const label = t(langKeys.duration)
  const hourAdornmentText = (
    value === 1 ? t(langKeys.hour) : t(langKeys.hours)
  ).toLocaleLowerCase()

  return (
    <MTextField
      label={label}
      type="number"
      endAdornment={hourAdornmentText}
      min={1}
      max={24}
      required
      value={value ? String(value) : ""}
      onChange={(number) => onChange(Number(number))}
    ></MTextField>
  )
}
